@import "variables";

.blog-container {
    padding: 30px 0;

    .blog-list {
        display: flex;
        flex-wrap: wrap;

        .blog-panel {
            padding: 0 15px;
            margin-bottom: 30px;

            .blog-card {
                text-decoration: none;
                color: inherit;
                border: 1px solid #e5e5e5;
                border-radius: 15px;
                transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                background-color: $body-bg;
                text-align: left;
                padding: 0;
                margin: 0;

                a {
                    color: inherit;
                    text-decoration: none;
                }

                img.card-img-top {
                    border-radius: 15px 15px 0px 0px;
                    margin-bottom: 0.5rem;
                    max-width: 100%;
                    aspect-ratio: 2 / 1.5;
                    height: auto;
                }

                .blog-card-text {
                    padding: 15px;
                    height: 13rem;
                    @media (max-width: 768px) {
                        height: 11rem;
                    }

                    p {
                        color: #666666;
                        font-size: 0.9em;
                        margin-bottom: 10px;
                    }

                    h5.card-title {
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    p.card-text {
                        font-size: 0.95em;
                        line-height: 1.5;
                        margin-bottom: 15px;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &:hover {
                    border-color: $lightestBlue; // Zmena obruby na nejakú farbu pri najetí
                    box-shadow: 0px 2px 15px 4px rgba(0, 0, 0, 0.1); // Pridaný tieň na hover
                }
            }
        }
    }

    .blog-intro-info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        flex-wrap: wrap;

        .blog-title-info {
            display: flex;

            p {
                font-size: 1.2rem;
                margin-bottom: 15px;
            }
        }
    }

    img {
        border-radius: 15px;
        margin-bottom: 15px;
        width: 100%;
    }

    .blog-content {
        margin-top: 1rem;
        background-color: $light;

        h2 {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.1rem;
            line-height: 1.8;
            margin-bottom: 1rem;
        }
    }
}
