
.menu {
    &.overflow-hidden { overflow: hidden; }
    &.overflow-visible { overflow: visible; }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    ul, li{
        list-style: none;
        padding: 0;
        margin: 0;
    }

    a{
        text-decoration: none;
    }

    .logo {
        .cisar-logo{
            height: 45px;
            width: 145px;
        }

    }

    .btn-hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @media (min-width: 768px) {
            display: none;
        }

        .icon {
            height: 30px; // prípadne uprav podľa potreby
            width: 30px; // prípadne uprav podľa potreby
        }

        .label {
            margin-left: 0.5rem;
            font-size: 0.875rem; // prípadne uprav podľa potreby
        }
    }

    .navigation {
        display: none;

        @media (min-width: 768px) {
            display: block;
        }

        .nav-list {
            display: flex;
            gap: 1rem;
            list-style-type: none;

            li {
                position: relative;

                .nav-item {
                    font-weight: bold;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: $black;
                    margin: 0px 20px;
                    display: inline-block;
                    transition: opacity 300ms;

                    &:hover {
                        opacity: 1;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        right: 0;
                        height: 3px;
                        background-color: transparent;
                        transition: background-color 300ms;
                    }

                    &:hover::before {
                        background-color: $red;
                    }

                    &.active {
                        opacity: 1;

                        &::before {
                            background-color: $red;
                        }
                    }
                }
            }
        }
    }
}

.mobile-nav {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: $white;
    transition: visibility 0.3s ease;
    z-index: 10;
    &.visible {
        visibility: visible;
    }

    ul, li{
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .nav-links {
        display: flex;
        position: absolute;
        width: 100%;
        transform: translateX(100%);
        transition: transform 0.3s ease;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        &.translate-x-0 {
            transform: translateX(0);
        }

        .nav-link {
            .link {
                display: block;
                padding: 1rem;
                text-decoration: none;
                font-size: 1.25rem;
                color: $black;
                text-transform: uppercase;

                &.active {
                    font-weight: bold;
                    color: $red;
                }
            }
        }
    }

    .close-btn {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        background: none;
        border: none;
        cursor: pointer;

        .close-icon {
            height: 30px;
            width: 30px;
        }
    }
}

@media (min-width: 768px) {
    .mobile-nav {
        display: none;
    }
}
