.gallery-container {
    padding: 30px 0;

    .gallery-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;

        .gallery-panel {
            padding: 0 15px;
            margin-bottom: 30px;

            .gallery-card {
                aspect-ratio: 1 / 0.7;
                border-radius: 15px;
                position: relative;
                transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                background-color: $body-bg;
                cursor: pointer;

                a {
                    color: inherit;
                    text-decoration: none;
                    z-index: 5;
                }

                img.card-img-top {
                    border-radius: 8px;
                    max-width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.5s ease-in-out;
                }


                &::after {
                    content: "";
                    position: absolute;
                    inset: 0;
                    border: 2px solid white;
                    border-radius: inherit;
                    opacity: 0;
                    transition: 0.4s ease-in-out;
                    pointer-events: none;
                }



                .gallery-body-overlay {
                    transition: 0.5s ease;
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 80%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    pointer-events: none;

                    p {
                        font-size: 0.9rem;
                        font-weight: 700;
                        color: $lightestBlue;
                        opacity: 0;
                        transform: translateY(20px);
                        transition: 0.5s ease-in-out;
                    }

                    h2 {
                        font-size: 1.8rem;
                        opacity: 0;
                        scale: 0.7;
                        color: $lightestBlue;
                        transition: 0.5s ease-in-out;
                    }
                }

                &:hover .card-img-top {
                    filter: grayscale(0) brightness(0.6);
                }

                &:hover::after {
                    opacity: 1;
                    inset: 20px;
                }

                @media screen and (max-width: 768px){
                    border-color: $lightestBlue;
                    box-shadow: 0px 2px 15px 4px rgba(0, 0, 0, 0.1);

                    img.card-img-top {
                        filter: grayscale(0) brightness(0.6);
                    }

                    &::after {
                        opacity: 1;
                        inset: 20px;
                    }

                    .gallery-body-overlay h2 {
                        opacity: 1;
                        scale: 1;
                    }

                    .gallery-body-overlay p {
                        opacity: 1;
                        transform: translateY(0);
                    }


                }

                &:hover .gallery-body-overlay h2 {
                    opacity: 1;
                    scale: 1;
                }

                &:hover .gallery-body-overlay p {
                    opacity: 1;
                    transform: translateY(0);
                }

                &:hover {
                    border-color: $lightestBlue;
                    box-shadow: 0px 2px 15px 4px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }


    .images {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-auto-flow: dense;

        .gallery-img-wrapper {
            width: 100%;

            .gallery-img {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 10px;
                aspect-ratio: 3 / 4;
            }
        }
    }
}

