// Body
$body-bg: #f8fafc;


// Typography
$font-family-sans-serif: "Nunito Sans", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


// Colors
$black: #000000;
$lightRed: #ffe4df;
$red: #d53636;
$lightBlue: #c9edf3;
$lightestBlue: #ecf9fb;
$secondary: #6c757d;
$success: #38c172;
$info: #6cb2eb;
$warning: #f6993f;
$yellow: #f4b400;
$blue: #3490dc;
$danger: #e3342f;
$light: #f8f9fa;
$dark: #343a40;
$white: #ffffff;
$body: #ffe4c4;
$grey: #a09f99;

$calcButtonColor: #d53636;
$background: #c9edf3;

$pagination-bg:                     $white;
$pagination-color:                  $black;

$pagination-hover-color:            $white;
$pagination-hover-bg:               $dark;
$pagination-hover-border-color:     $black;

$pagination-active-color:           $black;
$pagination-active-bg:              $lightBlue;
$pagination-active-border-color:    $pagination-active-bg;
