.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100vh;
    justify-content: center;

    .img-container {
        margin-bottom: 20px;
    }

    .login-container {
        form {
            display: flex;
            flex-direction: column;

            input[type=email], input[type=password] {
                margin: 10px 0;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;
            }

            button[type=submit] {
                background-color: #007bff;
                color: white;
                padding: 10px;
                border: none;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }
    }
}
