// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/dist/css/bootstrap.css';

// komponenty
@import "navigation";
@import "home";
@import "gallery";
@import "calculator";
@import "footer";
@import "login";
@import "blog";
@import "contact";
@import "gdpr";
@import "cookie";
@import "tooltip";

html {
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 50rem;
        background-color: $secondary; // zmenit farbu
    }

    scroll-behavior: smooth;
}

body {
    background-color: $body-bg;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-family: 'Nunito', sans-serif;
    margin: 0;
    overflow-x: hidden;
    box-sizing: border-box;


    .btn-back {
        color: $dark;
        font-weight: 600;
        text-decoration: none;
        letter-spacing: 2px;
        background-color: #e3f2fd;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 0.375rem;
        border: 0;

        &:hover {
            background-color: $dark;
            color: $white;
        }

        i {
            font-weight: 600;
        }
    }


    .back-link {
        color: $dark;
        font-weight: 600;
        text-decoration: none;
        letter-spacing: 2px;
        background-color: $lightBlue;
        display: inline-block;
        padding: 10px 20px;
        border-radius: 0.375rem;
        border: 0;

        &:hover {
            background-color: $dark;
            color: $white;
        }

        i {
            font-weight: 600;
        }
    }

    // Custom
    .btn-calculator-price {
        padding: 10px 40px;
        background-color: #d53636;
        display: inline-block;
        color: #ffffff;
        border-radius: 0.375rem;
        border: 0;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;
        height: 100%;

        &.is-navigation {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    //    a{
    //        text-decoration: none;
    //        color: inherit;
    //        letter-spacing: 2px;
    //    }
    //}

    .btn-service {
        padding: 10px 40px;
        border: 1px solid $dark;
        color: $black;
        background-color: $white;
        border-radius: 0.375rem;
        font-size: 1rem;
        font-weight: 600;

        a {
            letter-spacing: 2px;
            text-decoration: none;
            color: inherit;
        }

        @media (max-width: 768px) {
            margin-top: 1rem;
        }
    }

    .link-paginator {
        margin-top: 5rem;
        display: flex;
        justify-content: center;
    }

    img {
        display: block;
        max-width: 100%;

        &.main-image {
            filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.5));
            border-radius: 15px;
        }

    }

    .warning-contact-us {
        background: $lightRed;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 2rem;
        padding: 1rem;
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    h1 {
        font-size: 2.5rem;
        font-weight: bold;
    }

    h2 {
        font-size: 2rem;
        font-weight: 700;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 700;
    }

    h4 {
        font-size: 1.25rem;
        font-weight: 700;
    }

    p {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 0;
    }

    .custom-alert {
        position: fixed;
        top: 65px;
        right: 17px;
        margin: auto;
        border-radius: 10px;
        box-shadow: 7px 7px 13px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;

        img {
            mix-blend-mode: multiply;
        }

        p {
            font-size: 1.1rem;
            font-weight: 600;
            padding: 1rem;
            margin: 0;
        }

        &.success {
            background-color: $success;
            color: #000000;
            padding: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        &.error {
            background-color: $danger;
            color: #000000;
            padding: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        // Mobile styles
        @media (max-width: 768px) {
            top: 20%;
            right: 50%;
            transform: translate(50%, -50%);
            width: 90%;
            max-width: 410px;
            z-index: 100;
        }
    }
}


