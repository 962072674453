.footer-container {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12rem;

    a {
        text-decoration: none;
        color: inherit;
    }

    ul, li {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .footer-logo {
        display: flex;
        justify-content: center;
        padding-block: 2rem;
    }

    .footer-contact {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        a {
            text-decoration: none;
            font-size: 24px;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }

    .footer-col-data {
        display: flex;
        align-content: center;
        flex-flow: column;
        flex-wrap: wrap;

        @media (max-width: 500px) {
            padding-top: 1rem;
            align-items: center;

            .footer-ul {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .social-media-ul {
            display: flex;
        }
    }
}
