.cookie-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Polopriehľadné čierne pozadie
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    .container {
        padding: 0;
        max-width: 600px; // Maximálna šírka kontajnera

        .content-box {
            padding: 16px;
            background-color: $lightRed; // Svetložltá
            border-radius: 8px;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

            .content-flex {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .message-area {
                    text-align: justify;
                    padding: 8px;

                    p{
                        color: black;
                    }
                }

                .cookie-switches{
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    .cookie-switch {
                        display: flex;
                        align-items: center;

                        .cookie-label {
                            margin-left: 8px;
                        }
                    }
                }

                .cookie-button {
                    margin-top: 16px;
                    display: flex;
                    justify-content: center;

                    .agree-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 8px 16px;
                        border-radius: 4px;
                        font-size: 14px;
                        font-weight: 600;
                        color: $white;
                        background-color: $red;
                        cursor: pointer;

                        &:hover {
                            background-color: $red;
                        }
                    }
                }
            }
        }
    }
}
