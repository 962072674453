@import "variables";

[x-cloak] { display: none !important; }

.main-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .home-page-row{
        display: flex;
        align-items: center;

        @media (max-width: 770px) {
            flex-direction: column-reverse;
        }

        .home-image {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            @media (max-width: 768px) {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }

        .home-group{
                .home-text {
                    overflow: hidden;
                    display: flex;
                    flex-direction: row;

                    @media (max-width: 500px) {
                        flex-direction: column;

                        .main-title{
                            z-index: 1;
                            background-color: $light;
                        }
                    }

                    h1{
                        font-family: $font-family-sans-serif;
                        font-size: 2.5rem;
                        margin-bottom: 1rem;
                        font-weight: 700;
                    }

                    .roller{
                        @media (max-width: 500px) {
                            margin-left: 0;
                            height: 4rem;
                        }

                        line-height: 4rem;
                        position: relative;
                        margin-left: 15px;
                    }

                    .roller #rolltext {
                        position: absolute;
                        top: 0;
                        animation: primary-slide 8s infinite;

                        .plyn {
                            color: $yellow;
                        }
                        .voda{
                            color: $blue;
                        }
                        .kurenie{
                            color: $red;
                        }
                        .chladenie{
                            color: $grey;
                        }
                    }
                }


            .button-section{
                margin-top: 1rem;
            }
        }
    }
}

.second-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $lightBlue;
    padding-bottom: 3rem;

    .second-title {
        display: flex;
        justify-content: center;
        padding-top: 2rem;
    }

    .second-card {
        margin-top: 2rem;
        padding: 20px;
        background-color: $lightestBlue;
        height: auto;
        min-height: 17rem;
        border-radius: 15px;
        overflow: hidden;

        @media (max-width: 500px) {
            height: auto;
        }
    }

    .second-card .row {
        align-items: center;
    }

    .second-image {
        width: 100%;
        max-width: 60px;

        margin-left: 0.5rem;

        @media (max-width: 500px) {
            margin-left: 0;
        }
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    p {
        margin: 0;
    }
}

.service-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;

    .service-title{
        display: flex;
        justify-content: center;
        padding-block: 2rem;
    }

    .col-service-card{
        @media (max-width: 468px) {
            width: 50%;
        }
        .service-card{
            @media (max-width: 468px) {
                height: 10rem;
            }
            padding: 20px;
            height: 9rem;
            margin-top: 1rem;
            box-shadow: (2px 2px 10px rgba(0, 0, 0, 0.2));
            transition:all 0.1s ease-in-out;
            position: relative;
            border-radius: 15px;
            cursor: pointer;

            .service-image{
                max-width: 10rem;
                position: absolute;

                @media (max-width: 468px) {
                    max-width: 9rem;
                    top: 45px;
                    left: 0;
                }
            }
        }
    }

    .modal-content{
        .modal-img{
            img{
                border-radius: 15px;
            }
        }

        .modal-footer{
            justify-content: center;
            flex-wrap: nowrap;

            a{
                font-size: 14px;
            }
        }
    }

    .partners {
        margin: 40px 0;

        .logos {
            margin: 40px 0;
            overflow: hidden;
            background: white;
            white-space: nowrap;
            position: relative;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                width: 150px;
                height: 100%;
                content: "";
                z-index: 2;

                @media (max-width: 500px) {
                    width: 50px;
                }
            }

            &:before {
                left: 0;
                background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
            }

            &:after {
                right: 0;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
            }

            &:hover .logos-slide {
                animation-play-state: paused;
            }

            .logos-slide {
                display: inline-flex;
                animation: 60s slide infinite linear;

                @media (max-width: 500px) {
                    animation: 45s slide infinite linear;
                }

                img {
                    max-width: none;
                    height: 70px;
                    margin: 0 30px;
                    @media (max-width: 500px) {
                        margin: 0 10px;
                    }
                }
            }
        }
    }
}

.faq-container{
    background-color: $lightRed;
    .faq-row{
        padding-top: 3rem;
        padding-bottom: 3rem;

        .faq-card{
            @media (max-width: 500px) {
                margin: 2rem 0;
            }
            margin: 2rem;
        }
    }
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}


@keyframes primary-slide {
    0%{
        top:0;
    }
    11.11%{
        top: -4rem;
    }
    22.22%{
        top: -8rem;
    }
    33.33%{
        top: -12rem;
    }
    44.44%{
        top: -16rem;
    }
    55.56%{
        top: -20rem;
    }
    66.67%{
        top: -24rem;
    }
    77.78%{
        top: -28rem;
    }
    88.89%{
        top: -32rem;
    }
    100%{
        top: -32rem;
    }
}
