@import 'variables';

.terms_container {
    padding: 5px;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .paragraph_wrapper {
        max-width: 50rem;
        padding: 20px;

        .nadpis,
        .podnadpis_bold {
            font-weight: bold;
        }

        @media screen and (min-width: 992px) {
            p:not([class]) {
                padding-left: 1em;
            }
        }

        @media screen and (max-width: 992px) {
            width: auto;
        }
    }

    @media screen and (max-width: 992px) {
        margin: auto;
        width: 90%;
    }

}

.cookies-table {


  @media screen and (max-width: 992px) {
    scale: 0.9;
    margin: 0 auto;
  }

  table {
    max-width: 45.5rem;
    border-collapse: collapse;
    font-size: 18px;
    text-align: left;

  }
  th, td {
    padding: 12px;
    border: 1px solid #ddd;
  }

  @media screen and (min-width: 992px) {
      th:first-child,
      td:first-child {
          min-width: 10.04em;
      }
  }

  thead {
    background-color: #f4f4f4;
  }

  th {
    background-color: $calcButtonColor;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

}
