
.calculator-container {
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;

    h1 {
        font-weight: bold;
        text-align: center;

        @media(min-width: 768px) {
            margin-bottom: 3rem;
        }
    }

    .calculator-forms {
        background-color: $background;
        height: auto;
        margin-top: 3rem;
        padding-block: 3rem;
        padding-bottom: 0;

        @media (max-width: 768px) {
            margin-top: 0;
            padding-block: 1rem;
        }

        .disclaimer {
            margin-top: 2rem;
        }

        .prices-table {
            border-spacing: 0;
            border-collapse: collapse;
            min-width: 230px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
            margin: auto;
            width: 80%;
            overflow: hidden;
            border-radius: 15px;

            @media (max-width: 768px) {
                width: 100%;
            }

            thead tr{
                border-bottom: 2px solid $lightBlue;
            }

            tr {
                animation: pulseTr 0.8s ease-in-out;
            }

            @keyframes pulseTr {
                0% {
                    transform: scale(0.5);
                }
                50% {
                    transform: scale(1.1);
                }
                100% {
                    transform: scale(1);
                }
            }

            thead tr:first-child th:first-child {
                border-top-left-radius: 15px;
            }

            thead tr:first-child th:last-child {
                border-top-right-radius: 15px;
            }

            th, td {
                padding: 12px 15px;
                background-color: $lightestBlue;
                
                .donation {
                    display: block; 
                    width: 80%;
                    background-color: #2ab200; 
                    color: white;
                    padding: 5px; 
                    border-radius: 5px;
                    font-size: 12px;
                    margin-top: 5px; 
                }

                .discount {
                    display: block; 
                    width: 80%;
                    background-color: #ff5757; 
                    color: white;
                    padding: 5px; 
                    border-radius: 5px;
                    font-size: 12px;
                    margin-top: 5px; 
                }

                .final-price {
                    text-decoration: underline;
                }
            }

            .table-td-action {
                text-align: center;
            }

            .delete-button {
                cursor: pointer;
                font-size: 12px;
                background-color: $lightRed;
                padding: 3px 10px;
            }

            tfoot tr:last-child td:first-child {
                border-bottom-left-radius: 15px;
            }

            tfoot tr:last-child td:last-child {
                border-bottom-right-radius: 15px;
            }

            tfoot tr td {
                border-top: 2px solid $lightBlue; // Pridanie čiarovej hranice

            }
        }

        .form-container {
            gap: var(--dl-space-space-twounits);
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: auto;

            @media (max-width: 768px) {
                width: 100%;
                margin-top: 0;
            }

            .form-wrapper {

                .question-wrapper {
                    display: flex;
                    flex-direction: column;

                    label {
                        margin-bottom: 0.1rem;
                        margin-top: 0.7rem;

                        span{
                            font-size: 16px;
                        }
                    }

                    option {
                        font-size: 16.5px;
                    }

                    input, select {
                        width: 100%;
                        height: 45px;
                        border-radius: 10px;
                        font-size: 16.5px;
                        font-weight: bolder;
                        border: none;
                        outline: none;
                        padding: 0 1rem;
                        margin-top: 2px;
                        box-shadow: 0px 0px 10px 0px rgba(18, 18, 18, 0.1);

                        @media (max-width: 768px) {
                            width: 100%;
                        }
                    }

                    .calculator-input-radio {
                        input {
                            width: 3%;
                            height: 10px;
                        }
                    }
                }

                .calculate-price-button-wrapper {
                    display: flex;
                    justify-content: center;

                    .sum {
                        margin-block: 2rem;
                    }
                }
            }
        }
    }

    .calculator-cards {
        //flex: 0 0 auto;
        width: 100%;
        display: grid;
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
        //margin: auto;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        @media (max-width: 768px) {
            display: flex;
            margin: auto;
            flex: 0 0 auto;
            gap: 1.5rem;
            padding: 1rem;
            max-width: 330px;
            overflow-x: scroll;
            overflow-y: hidden;
            flex-wrap: nowrap;
        }

        .card {
            border: none;
            //display: flex;
            //flex-direction: column;
            //justify-content: center;
            //align-items: center;
            transition: 0.3s;
            height: 12rem;
            cursor: pointer;

            @media (max-width: 768px) {
                min-width: 6.5rem;
                min-height: 14.5rem;
                color: var(--text);
                font-weight: 600;
                font-size: 1.5rem;
                transition: all 0.2s;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                scroll-snap-align: start;
                flex-direction: row;
                user-select: none;
                scale: 0.6;
            }

            &:hover {
                transform: scale(1.02);
            }

            .card-shadows {
                box-shadow: 0px 0px 10px 0px rgba(18, 18, 18, 0.1);
                min-width: 7rem;
                height: 11rem;
                padding: 15px;
                border-radius: 15px;

                &.selected {
                    background-color: $lightBlue;
                }



                @media (max-width: 768px) {
                    min-width: 12.5rem;
                    min-height: 16.5rem;
                    scale: 0.9;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
                }

                img {
                    position: absolute;
                    top: 15%;
                    left: 15%;
                    max-width: 9rem;

                    @media screen and (max-width: 768px) {
                        top: 60px;
                        left: 10px;
                        max-width: 11rem;
                    }
                }

                .text-wrapper {
                    h4 {
                        font-size: 1rem;

                        @media screen and (max-width: 768px) {
                            font-size: 1.5rem;

                        }
                    }

                    .checkmark {
                        position: absolute;
                        right: 160px;
                        bottom: 32px;
                        scale: 1.3;
                        animation: pulse 0.8s ease-in-out;

                        @media screen and (max-width: 768px) {
                            scale: 1.3;

                        }
                    }

                    @keyframes pulse {
                        0% {
                            transform: scale(0.2);
                        }
                        50% {
                            transform: scale(1.2);
                        }
                        100% {
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        .calculator-cards::-webkit-scrollbar {
            height: 0.35rem;
        }

        .calculator-cards::-webkit-scrollbar-track {
            border-radius: 50rem;
        }

        .calculator-cards::-webkit-scrollbar-thumb {
            border-radius: 50rem;
            background-color: $secondary;
        }
    }
}

