.with-tooltip {
  display: flex;
}

.tooltip-wrapper {
  display: inline-block;
  margin-left: 2px;

  .tooltiptext {
    visibility: hidden;
    background-color: $secondary;
    color: #fff;
    min-width: 300px; 
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%); 
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.qestion-tooltip {
  position: relative;
}

.price-table-tooltip {
  margin-top: 2px;
  position: absolute;
}