@import "variables";


.contact-container {
    padding: 30px 0;

    .contact-panel {

        .panel {
            padding: 2rem;

            @media (max-width: 768px) {
                padding: 0;
            }

            p {
                font-size: 1.2rem;
                margin-bottom: 15px;
            }
        }

        img {
            border-radius: 15px;
            margin-bottom: 15px;
            width: 100%;
            mix-blend-mode: multiply;
        }
    }

    .contact-header {
        display: flex;
        justify-content: center;
    }

    .timeline {
        position: relative;
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        padding: 15px 0;
    }

    .timeline::after {
        content: '';
        position: absolute;
        width: 2px;
        background: $black;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -1px;
    }

    .timeline-body {
        padding: 15px 30px;
        position: relative;
        background: inherit;
        width: 50%;

        @media (max-width: 768px) {
            padding: 15px 0;
        }
    }

    .timeline-body.left {
        left: 0;
    }

    .timeline-body.right {
        left: 50%;
    }

    .timeline-body::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
        right: -8px;
        background: #ffffff;
        border: 2px solid $black;
        border-radius: 16px;
        z-index: 1;
    }

    .timeline-body.right::after {
        left: -8px;
    }

    .timeline-body::before {
        content: '';
        position: absolute;
        width: 50px;
        height: 2px;
        top: calc(50% - 1px);
        right: 8px;
        background: $black;
        z-index: 1;
    }

    .timeline-body.right::before {
        left: 8px;
    }

    .timeline-body .date {
        position: absolute;
        display: inline-block;
        top: calc(50% - 8px);
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: $red;
        text-transform: uppercase;
        letter-spacing: 1px;
        z-index: 1;
    }

    .timeline-body.left .date {
        right: -75px;
    }

    .timeline-body.right .date {
        left: -75px;
    }

    .timeline-body .icon {
        position: absolute;
        display: inline-block;
        width: 40px;
        height: 40px;
        top: calc(50% - 20px);
        background: $lightBlue;
        border: 2px solid $black;
        border-radius: 40px;
        text-align: center;
        font-size: 18px;
        color: $black;
        z-index: 1;

        img{
            margin-bottom: 0;
            width: 80%;
            position: absolute;
            top: 3px;
            left: 3px;
        }
    }

    .timeline-body.left .icon {
        right: 56px;
    }

    .timeline-body.right .icon {
        left: 56px;
    }

    .timeline-body .content {
        padding: 30px 90px 30px 30px;
        background: $lightBlue;
        position: relative;
        border-radius: 0 500px 500px 0;
    }

    .timeline-body.right .content {
        padding: 30px 30px 30px 90px;
        border-radius: 500px 0 0 500px;
    }

    .timeline-body .content h2 {
        margin: 0 0 10px 0;
        font-size: 18px;
        font-weight: normal;
        color: $black;
    }

    .timeline-body .content p {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
    }

    @media (max-width: 767.98px) {
        .timeline::after {
            left: 90px;
        }

        .timeline-body {
            width: 100%;
            padding-left: 120px;
        }

        .timeline-body.right {
            left: 0%;
        }

        .timeline-body.left::after,
        .timeline-body.right::after {
            left: 82px;
        }

        .timeline-body.left::before,
        .timeline-body.right::before {
            left: 100px;
            border-color: transparent #006E51 transparent transparent;
        }

        .timeline-body.left .date,
        .timeline-body.right .date {
            right: auto;
            left: 15px;
        }

        .timeline-body.left .icon,
        .timeline-body.right .icon {
            right: auto;
            left: 146px;
        }

        .timeline-body.left .content,
        .timeline-body.right .content {
            padding: 30px 30px 30px 90px;
            border-radius: 500px 0 0 500px;
        }

    }
}

#certificates {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0;
    }

    .certificate-card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.5s;

        .certificate-logo {
            max-width: 60%;
            max-height: 60%;
            cursor: pointer;


            &:hover {
                transform: scale(1.1);
                transition: transform 0.5s;
            }
        }
    }
}

#kontakt {
    margin-block: 3rem;
    @media (max-width: 768px) {
        margin-block: 1rem;
    }

    .contact-form-component {

        h2 {
            color: #333;
            text-align: center;
            padding-top: 20px;
        }

        .contact-form-row {
            display: flex;
            align-items: center;
        }

        .contact-form-panel {
            padding: 20px;

            form {
                .form-group {
                    margin-bottom: 15px;

                    &.form-agreement{
                        display: flex;
                        align-items: baseline;
                    }

                    label {
                        font-weight: bold;
                        display: block;
                        margin-bottom: 5px;
                    }

                    input[type="checkbox"] {
                        margin-left: 1rem;
                    }

                    input[type="text"],
                    input[type="email"],
                    input[type="file"],
                    select,
                    textarea {
                        width: 100%;
                        padding: 8px 10px;
                        border: 1px solid #ccc;
                        border-radius: 4px;

                        &:focus {
                            border-color: #0056b3;
                            box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.25);
                        }
                    }

                    .error {
                        color: #cc0000;
                        font-size: 0.85em;
                        margin-top: 5px;
                    }
                }

                .btn {
                    background-color: #0056b3;
                    color: white;
                    padding: 10px 15px;
                    border: none;
                    border-radius: 4px;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #003580;
                    }
                }
            }
        }
    }

}

